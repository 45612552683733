<template>
  <app-container :title="'实名认证'" :background="tool.getThemeList(theme)">
    <!-- 手动实名认证-->
    <div class="auth_box">
      <div class="auth_content">
        <div class="real_name">
          <span class="desc">真实姓名:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入真实姓名2一20个字符"
            v-model="realName"
            maxlength="20"
          />
        </div>
        <div class="real_id_underline"></div>
        <div class="id_card">
          <span class="desc">身份证号:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入身份证号码，19位"
            v-model="IDCard"
            maxlength="19"
          />
        </div>
        <div class="real_id_underline"></div>
        <div class="id_photo_one">
          <div class="left_text">证件照片:</div>
          <div class="center_photo_box">
            <div class="center_photo">
              <div class="center_photo_img">
                <img
                  @click="
                    previewImgFn({ img: list[0].imgObj.upload_Img1, index: 0 })
                  "
                  alt="logo"
                  :src="
                    list[0].imgObj.upload_Img1
                      ? list[0].imgObj.upload_Img1
                      : require(`_ASSETS_/image/auth/img_verified_idphoto_a.png`)
                  "
                />
              </div>
              <div class="right_choice">
                <div class="choice_add">
                  <input
                    type="file"
                    class="file"
                    accept="image/*"
                    @change="
                      file => {
                        uploadImg(file, 0);
                      }
                    "
                    v-if="true"
                  />
                </div>
                <div>身份证人像面</div>
              </div>
            </div>
            <div class="center_photo">
              <div class="center_photo_img">
                <img
                  @click="
                    previewImgFn({ img: list[1].imgObj.upload_Img2, index: 1 })
                  "
                  alt="logo"
                  :src="
                    list[1].imgObj.upload_Img2
                      ? list[1].imgObj.upload_Img2
                      : require(`_ASSETS_/image/auth/img_verified_idphoto_b.png`)
                  "
                />
              </div>
              <div class="right_choice">
                <div class="choice_add">
                  <input
                    type="file"
                    class="file"
                    accept="image/*"
                    @change="
                      file => {
                        uploadImg(file, 1);
                      }
                    "
                    v-if="true"
                  />
                </div>
                <div>身份证国徽面</div>
              </div>
            </div>
          </div>
        </div>
        <div class="id_photo_two">
          <div class="center_photo_box">
            <div class="center_photo">
              <div class="center_photo_img">
                <img
                  @click="
                    previewImgFn({ img: list[2].imgObj.upload_Img3, index: 2 })
                  "
                  alt="logo"
                  :src="
                    list[2].imgObj.upload_Img3
                      ? list[2].imgObj.upload_Img3
                      : require(`_ASSETS_/image/auth/img_verified_idphoto_c.png`)
                  "
                />
              </div>
              <div class="right_choice">
                <div class="choice_add">
                  <input
                    type="file"
                    class="file"
                    accept="image/*"
                    @change="
                      file => {
                        uploadImg(file, 2);
                      }
                    "
                    v-if="true"
                  />
                </div>
                <div>手持身份证</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="seeImgShow" round>
      <div class="previewBox">
        <div class="previewImg">
          <img :src="previewImg.img" alt="logo" />
        </div>
        <div class="previewButton" @click="deleteImg(previewImg.index)">
          删除
        </div>
      </div>
    </van-popup>
    <div class="submit_button" @click="submit">提交认证</div>
  </app-container>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { AuthenTicationManaul } from "_API_/api.services";
import { fileToImgFn } from "@/utils/utils";
import { cardid } from "@/utils/validate";
export default {
  setup() {
    const state = reactive({
      realName: "", //真实姓名
      IDCard: "", //身份证
      cardId: "", //卡ID
      elements: 1, //元素
      list: [
        {
          imgObj: {
            upload_Img1: "",
            file: {}
          }
        },
        {
          imgObj: {
            upload_Img2: "",
            file: {}
          }
        },
        {
          imgObj: {
            upload_Img3: "",
            file: {}
          }
        }
      ], //上传的图片src及传给后台的文件file
      seeImgShow: false, //预览弹框
      previewImg: {
        img: "",
        index: ""
      }, //预览的图片地址与索引
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    const { $router: router, $tool: tool, $store: store } = ctx;
    const methods = {
      //上传图片
      uploadImg(file, num) {
        switch (num) {
          case 0:
            var file = event.target.files[0];
            break;
          case 1:
            var file = event.target.files[0];
            break;
          case 2:
            var file = event.target.files[0];
            break;
          default:
            break;
        }
        if (!Boolean(file)) {
          return;
        }
        // 检测图片格式
        if (
          file.type != "image/png" &&
          file.type != "image/jpeg" &&
          file.type != "image/jpg"
        ) {
          tool.alert({
            title: "提示",
            msg: "只支持上传png和jpeg图片！"
          });
          return;
        }
        //检测图片大小
        if (file.size / 1024 > 2048) {
          tool.alert({
            title: "提示",
            msg: "图片最大不得超过2MB！"
          });
          return;
        }
        //以文件流形式传给后台
        ctx.list[num].imgObj.file = file;
        ctx.fileToImg(file, num);
        event.target.value = "";
      },
      // 图片转换处理,图片的 base64 格式, 可以直接当成 img 的 src 属性值
      async fileToImg(file, num) {
        switch (num) {
          case 0:
            let imgUrl_one = await fileToImgFn(file);
            ctx.list[0].imgObj.upload_Img1 = imgUrl_one;
            break;
          case 1:
            let imgUrl_two = await fileToImgFn(file);
            ctx.list[1].imgObj.upload_Img2 = imgUrl_two;
            break;
          case 2:
            let imgUrl_three = await fileToImgFn(file);
            ctx.list[2].imgObj.upload_Img3 = imgUrl_three;
            break;
          default:
            break;
        }
      },
      //预览图片
      previewImgFn(obj) {
        state.previewImg = obj;
        state.seeImgShow = true;
      },
      //删除图片
      deleteImg(index) {
        state.list[index].imgObj.file = {};
        switch (index) {
          case 0:
            state.list[index].imgObj.upload_Img1 = "";
            break;
          case 1:
            state.list[index].imgObj.upload_Img2 = "";
            break;
          case 2:
            state.list[index].imgObj.upload_Img3 = "";
            break;
          default:
            break;
        }
        state.seeImgShow = false;
      },
      //提交认证
      submit() {
        ctx.AuthenTicationManaul_();
      },

      AuthenTicationManaul_() {
        //检测真实姓名
        if (!Boolean(state.realName)) {
          tool.alert(
            {
              title: "提示",
              msg: "请输入真实姓名"
            },
            () => {
              console.log("检测真实姓名");
            }
          );
          return;
        }
        //检测身份证
        let result_cardId = cardid(state.IDCard);
        if (result_cardId[0]) {
          tool.alert(
            {
              title: "提示",
              msg: result_cardId[1]
            },
            () => {
              console.log("检测身份证", result_cardId[1]);
            }
          );
          return;
        }
        let conf = new FormData(); //创建一个新的 FormData 对象。
        //向 FormData 中添加新的属性值
        conf.append("realName", state.realName);
        conf.append("idcardNumber", state.IDCard);
        conf.append("cardId", state.cardId);
        conf.append("elements", state.elements);
        state.list.forEach(item => {
          conf.append("file", item.imgObj.file);
        });
        //检测图片上传
        if (
          state.list[0].imgObj.upload_Img1 &&
          state.list[1].imgObj.upload_Img2 &&
          state.list[2].imgObj.upload_Img3 !== ""
        ) {
          // 手动实名认证
          AuthenTicationManaul(conf).then(({ data: res }) => {
            if (res.code == 0) {
              router.push({
                name: "realName-wait"
              });
              return;
            }
            tool.alert(
              {
                title: "提示",
                msg: res.msg,
                confirmButtonText: "确定"
              },
              () => {}
            );
          });
        }
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      if (Object.keys(info).length > 0) {
        state.cardId = info.cardId;
      }
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.auth_box {
  width: 690px;
  height: 824px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 20px;
  .auth_content {
    width: 630px;
    height: 800px;
    margin: 0 auto;
    .id_photo_one,
    .id_photo_two {
      display: flex;
      justify-content: space-around;
      .left_text {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .center_photo_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .center_photo {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .center_photo_img {
            img {
              width: 268px;
              height: 168px;
            }
          }
          .right_choice {
            width: 148px;
            height: 118px;
            border-radius: 20px;
            font: {
              size: 22px;
              weight: 400;
            }
            color: #2196f3;
            border: 2px dashed #aaaaaa;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            .choice_add {
              img {
                width: 42px;
                height: 42px;
              }
              .file {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                overflow: hidden;
              }
              .file::before {
                display: block;
                content: "";
                width: 50px;
                height: 50px;
                overflow: hidden;
                background: url("../../assets/image/auth/icon_add.png");
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: #fff;
              }
            }
          }
        }
      }
      .id_photo_one {
        .center_photo_box {
          height: 380px;
        }
      }
      .id_photo_two {
        .center_photo_box {
          height: 180px;
        }
      }
    }
    .id_photo_one {
      height: 376px;
      margin-top: 40px;
    }
    .id_photo_two {
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 20px;
    }
    .real_name,
    .id_card {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
    }
    .real_name,
    .id_card {
      width: 630px;
      margin-left: 20px;
      input {
        width: 430px;
        margin-left: 40px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .real_id_underline {
      width: 430px;
      height: 1px;
      background: #f2f2f2;
      margin: 0 180px;
    }
  }
}
.previewBox {
  display: flex;
  flex-direction: column;
  .previewImg {
    img {
      width: 268px;
      height: 168px;
    }
  }
  .previewButton {
    text-align: center;
    color: #ffffff;
    background: #ff4937;
  }
}

.submit_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
  margin: 50px auto 20px;
  text-align: center;
  line-height: 84px;
}
</style>
